var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.99987 1.51677C5.47991 0.527841 7.21997 0 9 0C11.387 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 10.78 17.4722 12.5201 16.4832 14.0001C15.4943 15.4802 14.0887 16.6337 12.4442 17.3149C10.7996 17.9961 8.99002 18.1743 7.24419 17.8271C5.49836 17.4798 3.89472 16.6226 2.63604 15.364C1.37737 14.1053 0.520203 12.5016 0.172937 10.7558C-0.174331 9.00998 0.00389963 7.20038 0.685088 5.55585C1.36628 3.91131 2.51983 2.50571 3.99987 1.51677ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35473 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.5 7.01088 15.7098 5.10322 14.3033 3.6967C12.8968 2.29018 10.9891 1.5 9 1.5Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.00912 5.06241C8.52587 5.06976 8.06738 5.17396 7.70405 5.46665C7.36217 5.74173 7.11276 6.32179 7.02987 6.52514C7.00668 6.88163 6.70177 7.15501 6.34355 7.13782C5.98153 7.12044 5.70214 6.81288 5.71952 6.45086L5.71959 6.4495L5.71966 6.44807L5.71983 6.44496L5.72026 6.43779L5.72158 6.41973C5.72269 6.40607 5.7243 6.38917 5.72663 6.36928C5.73129 6.32953 5.73886 6.27765 5.75113 6.21567C5.77564 6.09186 5.81921 5.92637 5.89675 5.73615C6.053 5.35282 6.34477 4.87582 6.88067 4.44455C6.88067 4.44455 7.751 3.75003 8.99161 3.75003C9.6617 3.75003 10.1283 3.83314 10.5257 4.02227C11.0258 4.26034 12.2813 4.96705 12.2813 6.54091C12.2813 8.1294 11.1885 8.862 10.3158 9.44702L10.2857 9.4672C9.90098 9.72519 9.69507 9.96768 9.58081 10.1808C9.46765 10.3919 9.42189 10.6161 9.42189 10.875C9.42189 11.2374 9.12808 11.5312 8.76564 11.5312C8.4032 11.5312 8.10939 11.2374 8.10939 10.875C8.10939 10.4509 8.18691 10.0031 8.42403 9.56075C8.66003 9.12048 9.03139 8.72804 9.55466 8.37713C10.4932 7.74774 10.9688 7.37161 10.9688 6.54091C10.9438 5.37365 9.65193 5.06889 9.00912 5.06241Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.71875 14.25C9.23652 14.25 9.65625 13.8303 9.65625 13.3125C9.65625 12.7947 9.23652 12.375 8.71875 12.375C8.20098 12.375 7.78125 12.7947 7.78125 13.3125C7.78125 13.8303 8.20098 14.25 8.71875 14.25Z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }