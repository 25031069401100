<template>
  <div :class="`${inline ? 'flex' : null} items-center mb-4`">
    <input type="checkbox" id="default-checkbox" :value="value" class="styled-checkbox" @change="$emit('select', !value)" />
    <label for="default-checkbox" class="text-sm">{{ description }}</label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.styled-checkbox {
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }
  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #3b82f6;
    border-radius: 4px;
    margin-right: 10px;
  }
  &:hover + label:before {
    background: #3b82f6;
    opacity: 0.3;
  }
  &:checked + label:before {
    background: #3b82f6;
  }
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
