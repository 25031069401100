var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "".concat(_vm.inline ? 'flex' : null, " items-center mb-4")
  }, [_c('input', {
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "default-checkbox"
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('select', !_vm.value);
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": "default-checkbox"
    }
  }, [_vm._v(_vm._s(_vm.description))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }